@import url("https://fonts.googleapis.com/css?family=Arima+Madurai");

// axa https://mdbootstrap.com/support/angular/toast-alerts-opacity-hard-to-read/
#toast-container > mdb-toast-component {
  opacity: 1 !important;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100) !important;
}

html,
body {
  overflow-x: hidden;
}

body,
p,
div,
h1,
h2,
h3,
h4,
h5,
h6,
span,
a,
button,
li,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.nav-link {
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif !important;
  font-weight: 400 !important;
}

body,
h6,
.md-accordion .card .card-body,
button {
  font-size: 1rem;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input,
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}

.loading-hardfix {
  top: 90px;
  left: 40% !important;
  z-index: 999 !important;
  position: absolute;
}

.progress {
  .mat-progress-bar-buffer {
    background-color: #aad27b !important; // static light bg-colour
  }

  .mat-progress-bar-secondary {
    background-color: #aad27b !important; // static light bg-colour
  }
  .mat-progress-bar-fill::after {
    background-color: #65c19f !important; // infinite scrolling bg-colour
  }
}

.m5r {
  margin: 5rem;
}

.mt6r {
  margin-top: 6rem;
}

.b-n {
  border: none;
}

// .fr-emoticon {
//   background-repeat: no-repeat !important;
//   font-size: inherit;
//   height: 1em;
//   width: 1em;
//   min-height: 20px;
//   min-width: 20px;
//   display: inline-block;
//   margin: -0.1em 0.1em 0.1em;
//   line-height: 1;
//   vertical-align: middle;
// }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%343A00' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}
.carousel-control-prev-icon {
  margin-left: 10px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%343A00' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

// .carousel-caption {
//   background-color: #4ab3c6;
//   opacity: 0.6;
// }
.mydp .markdate {
  width: 100% !important; // 10px
  height: 3px !important; //10px
  opacity: 0.5 !important;
  border-radius: 0% !important;
  top: 32px !important;
  right: 0px !important;
  margin: 1px !important;
}

.mydp {
  //margin-left: -150px !important;
  position: relative !important;
}

.w100 {
  width: 100%;
}

.b0 {
  border: 0;
}

.center {
  text-align: center;
}

.top-nav-collapse {
  background: #59698d;
}

.navbar {
  background: #59698d;
  margin-top: -15px !important;
}

.accordion .active .card-header {
  background: #02afc4 !important;
  color: #ffffff;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #6c757d !important;
}

// @media only screen and (max-width: 450px) {
//   .bmt {
//     padding-left: 20px;
//     padding-right: 20px;
//   }
// }

.fixed-top {
  z-index: 99;
}

// .picker__box .picker__table {
//   margin-top: 0;
//   margin-bottom: 0;
//   font-size: 0.8rem;
// }
// .picker--opened .picker__holder {
//   top: 5px;
// }

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.accordion .active .card-header {
  background: #02afc4;
  color: #ffffff;
}

// .picker--opened .picker__holder {
//   top: 0;
// }

/*.picker__frame {
  min-height: 560px !important;
}*/

// .btn {
//   font-size: 1rem !important;
// }

// .form-control {
//   width: 91% !important;
// }

input.form-control.validate-success.ng-valid.ng-dirty,
input.form-control.validate-success.ng-valid.ng-touched,
.form-submitted input.form-control.validate-success.ng-valid {
  border-bottom: 1px solid #02afc4 !important;
}

.navbar.navbar-dark form .md-form input:focus:not([readonly]) {
  border-color: #02afc4 !important;
}

.sticky-top {
  position: fixed !important;
  width: 100%;
}

@media (min-width: 1200px) {
  .container {
    max-width: none !important;
    flex-wrap: wrap !important;
  }
}

.w-100-btn {
  width: 100% !important;
}

.f25 {
  font-size: 25px;
  color: #8c8c8c !important;
}

@media (max-width: 768px) {
  .form-control {
    width: 83.5% !important;
  }

  .w-100 .form-control {
    width: 100% !important;
  }
  .f25 {
    font-size: 18px;
  }
}

.mt-0 {
  margin-top: 0;
}

.mt-90 {
  margin-top: 70px;
}

.mt-10 {
  margin-top: 10px;
}
.mt-5 {
  margin-top: 5px;
}
.pt-10 {
  margin-top: 10px;
}

.navbar-nav {
  flex-direction: row !important;
}

.badge {
  font-size: 100%;
}
.speech-bubble {
  position: relative;
  background: #c1c2c2;
  border-radius: 0.4em;
  padding-left: 15px;
  width: 100%;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  top: 0;
  left: 38%;
  width: 0;
  height: 0;
  border: 18px solid transparent;
  border-bottom-color: #c1c2c2;
  border-top: 0;
  border-right: 0;
  margin-left: -44px;
  margin-top: -16px;
}

.bb-n {
  border-bottom: none !important;
  line-height: 1.2 !important;
}

.md-form input[type="text"]:focus:not([readonly]),
.md-form input[type="password"]:focus:not([readonly]),
.md-form input[type="email"]:focus:not([readonly]),
.md-form input[type="url"]:focus:not([readonly]),
.md-form input[type="time"]:focus:not([readonly]),
.md-form input[type="date"]:focus:not([readonly]),
.md-form input[type="datetime-local"]:focus:not([readonly]),
.md-form input[type="tel"]:focus:not([readonly]),
.md-form input[type="number"]:focus:not([readonly]),
.md-form input[type="search-md"]:focus:not([readonly]),
.md-form input[type="search"]:focus:not([readonly]),
.md-form textarea.md-textarea:focus:not([readonly]) {
  box-shadow: 0 1px 0 #65c19f !important;
}

@media (max-width: 768px) {
  .bb-n {
    width: 78% !important;
  }
}

.form-check-input[type="checkbox"] + label,
label.btn input[type="checkbox"] + label,
.form-check-input[type="radio"]:not(:checked) + label,
.form-check-input[type="radio"]:checked + label,
label.btn input[type="radio"]:not(:checked) + label,
label.btn input[type="radio"]:checked + label {
  height: 1.65rem !important;
  line-height: 1.65rem !important;
  background: #fff;
  border: 1px solid #eeeeee;
  border-radius: 20px;
  padding: 0 1.5rem !important;
}

.form-check-input[type="checkbox"] + label:before {
  left: 5px !important;
}

.form-check-input[type="checkbox"].filled-in:checked + label:before,
.form-check-input[type="checkbox"].filled-in:checked + label:after,
.form-check-input[type="checkbox"].filled-in + label:before,
.form-check-input[type="radio"]:checked + label:before,
.form-check-input[type="radio"]:checked + label:after,
.form-check-input[type="radio"]:not(:checked) + label:before {
  display: none;
}

.form-check-input[type="checkbox"].filled-in:not(:checked) + label:after,
.form-check-input[type="radio"]:not(:checked) + label:after {
  height: 100% !important;
  width: 100% !important;
  border-radius: 20px !important;
  // background-color: rgba(2,175,196, 0.3) !important;
  border: none !important;
}

.form-check-input[type="checkbox"].filled-in:checked + label,
.form-check-input[type="radio"]:checked + label,
.btn-ic {
  background-color: rgba(2, 175, 196, 1) !important;
  color: #fff !important;
}

#checkbox-teal.form-check-input[type="checkbox"].filled-in:checked + label,
#checkbox-teal.form-check-input[type="radio"]:checked + label {
  background-color: #65c19f !important;
  color: #fff !important;
}

.accordion .card .card-header {
  color: rgba(2, 175, 196, 1) !important;
  padding: 0.8rem 0 0 !important;
  border-bottom: 0 !important;
  background: #ffffff !important;
}

.md-accordion .card .card-header a {
  height: 2rem !important;
  padding-left: 20px !important;
}

.logo1,
.logo-m {
  height: 67.59px !important;
  padding: 2% 8% !important;
}

.h5r {
  height: 5rem;
}

.rgba-white-strong,
.rgba-white-strong:after {
  background-color: rgba(255, 255, 255, 1) !important;
}

.f-b {
  position: relative;
  bottom: 0;
  left: 2px;
  padding-top: 15px;
}

.f-b-btn {
  padding: 12px 10px !important;
  height: 40px !important;
  line-height: 1 !important;
  font-size: 0.81rem !important;
}

@media (max-width: 768px) {
  .logo-m {
    height: 50px !important;
    padding: 2% 8% !important;
  }
}

mdb-side-nav .mdb-accordion-indicator {
  top: 0.8rem !important;
}

@media (max-width: 375px) {
  .logo1 {
    height: 50px !important;
  }
  body,
  h6,
  .md-accordion .card .card-body,
  button,
  .md-form label {
    font-size: 0.7rem !important;
  }
  .side-nav a,
  h5,
  .h5 {
    font-size: 0.81rem !important;
  }
  .md-accordion .card .card-header a {
    height: 1.3rem !important;
  }
  mdb-side-nav .mdb-accordion-indicator {
    top: 0.2rem !important;
  }
  .form-check-input[type="checkbox"] + label,
  label.btn input[type="checkbox"] + label {
    padding: 0 1rem !important;
  }
}

mdb-select > div > div.single.active,
mdb-select > div > div.single:active,
mdb-select > div > div.single:hover {
  border-bottom-color: #02afc4 !important;
}

mdb-select-dropdown .dropdown-content li > a,
mdb-select-dropdown .dropdown-content li > span,
.picker__box .picker__table td.picker__day div.picker__day.picker__day--today,
.i-green {
  color: #02afc4 !important;
}

.side-nav {
  padding-bottom: 46px !important;
}

.dropdown .dropdown-menu.dropdown-primary .dropdown-item.active,
.dropdown .dropdown-menu.dropdown-primary .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-primary .dropdown-item:hover,
.dropleft .dropdown-menu.dropdown-primary .dropdown-item.active,
.dropleft .dropdown-menu.dropdown-primary .dropdown-item:active,
.dropleft .dropdown-menu.dropdown-primary .dropdown-item:hover,
.dropright .dropdown-menu.dropdown-primary .dropdown-item.active,
.dropright .dropdown-menu.dropdown-primary .dropdown-item:active,
.dropright .dropdown-menu.dropdown-primary .dropdown-item:hover,
.dropup-material .dropdown-menu.dropdown-primary .dropdown-item.active,
.dropup-material .dropdown-menu.dropdown-primary .dropdown-item:active,
.dropup-material .dropdown-menu.dropdown-primary .dropdown-item:hover,
.i-green-bg {
  background-color: #02afc4 !important;
}

.i-green-d {
  color: #65c19f !important;
}

.i-green-d-bg {
  background-color: #65c19f !important;
}

.i-green-l {
  color: #aad27b !important;
}

.i-green-l-bg {
  background-color: #7c9b58 !important;
}

.py-05 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.side-nav {
  width: 13rem !important;
}

.dropdown-menu {
  left: -125px !important;
}

.ab-action-btn {
  position: absolute !important;
  z-index: 998;
  top: 0;
  right: 0;
}

.btn-tlight {
  background-color: rgba(199, 199, 199, 0.7) !important;
}

.md-form .form-control {
  padding: 1rem 0;
}

.md-form .prefix ~ label {
  margin-left: 0 !important;
}

.hm {
  width: 100%;
  max-width: 1285px;
  box-sizing: border-box;
  margin: auto auto 80px !important;
}

.cardfl {
  margin-right: 24px;
  max-width: calc(25% - 25px);
  flex: 1 1 0%;
  float: left;
  margin-bottom: 30px;
}

.clearfix {
  clear: both;
}

.rwdbtn {
  padding: 0.8rem !important;
  font-size: 16px;
  margin-bottom: 60px;
}

.ma-center {
  margin: 0 auto !important;
}

.rwdcenter {
  text-align: center;
}

.smallbanner,
.smallhero {
  display: none;
}

@media (max-width: 1024px) {
  .bigbanner {
    display: none;
  }
  .smallbanner {
    display: block !important;
  }

  .hm {
    box-sizing: border-box;
    margin: auto auto 5px !important;
  }

  .rwdbtn {
    font-size: 15px !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
}
.mt2r {
  padding-top: 2.2rem !important;
}

@media (max-width: 600px) {
  .mt2r {
    padding-top: 20px !important;
  }
  .bighero {
    display: none !important;
  }
  .smallhero {
    display: block !important;
  }
  .row4 {
    width: 40% !important;
    float: left !important;
    margin-right: 10px !important;
  }
  .row6 {
    width: 100% !important;
  }

  h5,
  .h5 {
    font-size: 1rem !important;
  }
  .rwdbtn {
    width: 70% !important;
  }
}

.f13 {
  font-size: 13px;
}
.f-right {
  position: absolute;
  right: 20px;
  margin-top: 5px;
}
.bottom_line {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.fixed-line-icon-footer {
  position: fixed;
  width: 100% !important;
  margin: 100 !important;
  margin-left: -115px !important;
  z-index: 99999;
}

.line-icon {
  position: absolute;
  top: -180px;
  right: 250px;
}

.line-icon-home {
  position: absolute;
  top: -170px;
  right: 350px;
}

.fixed-w100 {
  position: fixed;
  width: 100% !important;
  margin: 0 !important;
  background: #2bbbad !important;
  margin-left: -15px !important;
  z-index: 99999;
}

.dropdown-content li > a,
.dropdown-content li > span,
.btn {
  font-size: 0.9rem !important;
}

.custom-control-inline {
  margin-right: 0rem !important;
}

.dropdown .dropdown-menu .dropdown-item,
.dropleft .dropdown-menu .dropdown-item,
.dropright .dropdown-menu .dropdown-item,
.dropup-material .dropdown-menu .dropdown-item,
.collapse-header {
  font-size: 1rem !important;
}

.mb-h {
  margin-bottom: 30px !important;
}

.lh-0 {
  margin-bottom: -2rem !important;
}

.cal-month-view .cal-day-cell {
  min-height: 40px !important;
}
table.table td,
table.table th {
  padding: 0.5rem 16px !important;
}

table th {
  font-size: 1rem !important;
}

.cal-month-view .cal-day-number,
.cal-month-view .cal-day-cell.cal-today .cal-day-number {
  margin-top: 5px !important;
  font-size: 1em !important;
  margin-bottom: 0 !important;
}

.cal-month-view .cal-cell-top {
  min-height: 30px !important;
}
